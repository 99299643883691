<template>
  
    <div class="pageMain">
      <div class="flex">
        <!-- 组织项目树 -->
        
        <OriginTree class="tree" :filterText="filterText" @checked="init">
          <template slot="search">
            <SearchLeft @search="search" @reset="reset"   
              info="项目信息"
              :iconShow="false">
              <el-form :inline="true" :model="searchForm">
                <el-form-item label="" class="formItem">
                  <el-input
                    v-model="filterText"
                    size="small"
                    placeholder="项目"
                  ></el-input>
                </el-form-item>
              </el-form>
            </SearchLeft>
          </template>
        </OriginTree>
  
        <!--右边列表  -->
        <div class="form" >
          <deviceTotal :item="item" :item2="item2"></deviceTotal>
           
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import OriginTree from "../../../components/common/OriginTree.vue";
  import SearchHead from "../../../components/common/SearchHead";
  import CustomTables from "../../../components/common/CustomTables";
  import SearchLeft from "../../../components/common/SearchLeft.vue";
  import deviceTotal from '../../../components/monitor/deviceTotal.vue'
  import monitor from '../../../api/modules/monitor'
  export default {
    components: {
      OriginTree,
      SearchHead,
      CustomTables,
      SearchLeft,
      deviceTotal
    },
    name: "statistics",
    data() {
      return {
        searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
        tableData: [
         
        ],
        options: [
          {
            value: "选项1",
            label: "黄金糕",
          },
          {
            value: "选项2",
            label: "双皮奶",
          },
          {
            value: "选项3",
            label: "蚵仔煎",
          },
          {
            value: "选项4",
            label: "龙须面",
          },
          {
            value: "选项5",
            label: "北京烤鸭",
          },
        ],
        headerCellStyle: {
          backgroundColor: "#f3f2f7",
          color: "#373628",
        },
        total: 0,
        show: true,
        filterText:'',
        item2:'',
        item:{}
      };
    },
    mounted() {},
    methods: {
      addFrom() {},
      init(val) {
        this.item2 =val.orgaId
      this.searchForm.orgaId = val.orgaId;
      this.getList();
    },
    getList() {
      monitor.getCounts(this.searchForm).then((res) => {
        // this.total = res.data.total;
        // this.tableData = res.data.list;
        this.item =res.data
      });
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    doneDialog(){
        this.showDialog=false
        this.getList();
      },
    reset() {
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
      };
      this.getList();
    },
      tableExport() {
        // 导出
      },
      handleSelectionChange(val) {
        console.log(val);
      },
      detailItem(){
          //详情
      },
      deleteItem(){}
    },
  };
  </script>
  
  <style scoped lang="less">
 .flex {
    display: flex;
    width: 100%;
    height: 100%;
    // justify-content: space-between;
    // justify-content: space-between;
  }
  .tree {
    width: 20%;
    height: 96%;
    // height: 580px;
  }
  .form {
    width: 78%;
    margin-left: 2%;
  }
  .formItem {
    margin-bottom: 0px;
  }
  .btn {
    background-color: #008f4d;
    // color: #008f4d;
  }
  
  .detail{
      color: #008f4d;
  }
  .delete{
    margin-left: 10px;
}
  </style>
  